@import '../../../../scss/theme-bootstrap';

.tout-block {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  display: flex;
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    &-pad-default {
      padding-bottom: 0;
    }
  }
  &__content,
  &__content-wrapper,
  &__media-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  &__media-wrapper {
    padding-top: 24px;
    &.media-variation {
      &-circle {
        img,
        video {
          border-radius: 50%;
        }
      }
      &-square {
        img,
        video {
          aspect-ratio: 1/1;
          object-fit: cover;
        }
      }
      &-rounded {
        img,
        video {
          border-radius: 10px;
        }
      }
    }
    &.align-media {
      margin-top: auto;
      &-center {
        margin: auto;
      }
      &-top {
        margin-bottom: auto;
      }
      &-bottom {
        margin-top: auto;
      }
    }
    &.tout-block-align-top-default {
      padding-top: 0;
    }
  }
  &__media {
    display: block;
    width: 100%;
    height: auto;
  }
  &__content-above-media-wrapper {
    padding-top: 0;
    .tout-block & {
      .content-block__line--header {
        margin-bottom: 15px;
        @include breakpoint($landscape-up) {
          margin-bottom: 20px;
        }
      }
      .content-block__line--eyebrow {
        .style--bare {
          @include typography-body-text;
          font-size: $medium-text-font-size;
          line-height: $medium-text-line-height;
        }
      }
    }
    &.mobile-hidden {
      + .tout-block__media-wrapper {
        @include breakpoint($small-down) {
          padding-top: 0;
        }
      }
    }
  }
  &__content-above-media {
    &.tout-block__cta-beside {
      flex-direction: row;
      justify-content: space-between;
      width: auto;
      flex-wrap: wrap;
      .content-block__above-text--eyebrow + .tout-block__link-wrapper {
        margin-top: 0;
      }
      .content-block__content:first-child {
        .content-block__line--content {
          margin-top: 0;
        }
        & + .tout-block__link-wrapper {
          margin-top: 0;
        }
      }
      .content-block__content {
        flex: 0 0 70%;
      }
    }
  }
  &__content-over-media {
    position: absolute;
    width: 100%;
    padding: 15px;
    z-index: 9;
    top: 0;
    bottom: 0;
    @include breakpoint($landscape-up) {
      padding: 20px;
    }
    .tout-block-no-media & {
      position: relative;
    }
  }
  &__content-wrapper {
    overflow: hidden;
    z-index: 1;
    padding-top: 24px;
    &.reduced-margin {
      padding-top: 0;
      .content-block__line {
        &--eyebrow {
          margin-bottom: 10px;
        }
        &--content {
          margin: 10px 0 0;
        }
      }
    }
    &.body-1 {
      margin: 0;
    }
    &:not(.tout-block__extra-column) {
      .extra-content-padding-default {
        padding-top: 24px;
      }
    }
  }
  &__link-wrapper {
    display: inline-flex;
    margin-top: 32px;
    margin-bottom: 0;
    .content-block__link {
      a.link {
        font-size: 17px;
      }
    }
    .tout-block__cta-beside & {
      margin-top: 9px;
      flex: auto;
      text-align: $rdirection;
      display: unset;
    }
    &.tout-block__link-max-width {
      width: 100%;
      display: inline-block;
      .content-block__link {
        &.max-width-auto {
          button {
            width: auto;
          }
        }
        .button {
         width: 100%;
        }
      }
    }
    &--no-margin {
      margin-top: 0;
    }
  }
  .content-block__line {
    margin: 0;
  }
  .content-block__content {
    height: auto;
    flex-grow: 0;
  }
  .content-block__line--header {
    margin: 0;
  }
  .content-block__line--content {
    margin: 9px 0 0;
  }
  p {
    margin-bottom: 0;
    line-height: inherit;
  }
  .mantle-custom-text {
    text-align: unset;
  }
  .headline-1,
  .headline-2 {
    line-height: 0.85;
  }
  .headline-6,
  .headline-5,
  .headline-4,
  .headline-3 {
    line-height: 1;
    letter-spacing: 0;
  }
  .body-1,
  .body-2 {
    line-height: 1.1;
    margin: 0;
  }
  .link {
    @include typography-link-text;
    text-transform: none;
  }
  .video-block {
    &:not([data-default-aspect-ratio='1']),
    &:not([data-default-aspect-ratio-mob='1']) {
      .videojs-video {
        padding-top: 100%;
      }
    }
  }
  &__content-below-eyebrow {
    padding-bottom: 10px;
  }
  &__vertical-border-pc {
    &.tout-block__extra-column {
      .tout-block__extra-content {
        @include breakpoint($landscape-up) {
          border-#{$ldirection}: 1px solid $color-gray-op15;
        }
        .dark-background & {
          border-color: $color-white-op15;
        }
      }
    }
  }
  &__extra-column {
    @include breakpoint($landscape-up) {
      padding-top: 0;
      display: flex;
      flex-direction: row;
    }
    .extra-content-padding-default {
      padding-top: 24px;
      @include breakpoint($landscape-up) {
        padding-top: 40px;
        flex: 50%;
      }
    }
    .tout-block__below_media_content {
      @include breakpoint($landscape-up) {
        padding-top: 40px;
        padding-#{$rdirection}: 20px;
        flex: 50%;
      }
    }
    &.tout-block__vertical-border-pc {
      @include breakpoint($landscape-up) {
        padding: 0;
        .tout-block__below_media_content {
          @include swap_direction(padding, 40px 10px 24px 0);
        }
        .extra-content-padding-default {
          @include swap_direction(padding, 40px 0 24px 10px);
        }
      }
    }
  }
  &__has-extra-copy {
    p {
      margin: 0;
    }
    &:not(.tout-block__extra-column) {
      &.tout-block__vertical-border-pc {
        border: none;
      }
    }
  }
}
